@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria-ExtraBold.woff2')
    format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('https://landboard-presale.vercel.app/geometria/Geometria-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://landboard-presale.vercel.app/lato/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://landboard-presale.vercel.app/lato/Lato-Regular_1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
